import React, { Dispatch, SetStateAction } from "react"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"

type P = {
	categories: string[]
	currentCategory: string
	setCategory: Dispatch<SetStateAction<string>>
}

export const Sidebar = ({ categories, currentCategory, setCategory }: P) => {
	const { t } = useTranslation()

	return (
		<div className="bg-blue-50 flex flex-col gap-8 sm:w-[300px] shrink-0 p-10">
			<div className="flex flex-col gap-4">
				<p className="uppercase">{t("common.words.contact-us")}</p>
				<p>
					<Trans
						i18nKey="faqs-page.sidebar.message"
						components={{
							anchor: <Link to="/contact-us/" className="anchor" />,
						}}
					/>
				</p>
			</div>

			<div className="w-full h-[1px] bg-gray-300"></div>

			<div className="flex flex-col gap-4">
				<p className="uppercase">{t("faqs-page.sidebar.heading")}</p>

				<ul className="flex flex-col gap-1">
					{categories.map((cat) => (
						<li
							key={cat}
							className={`
                                cursor-pointer text-theme-blue 
                                ${cat === currentCategory ? "font-bold" : ""}
                            `}
							onClick={() => setCategory(cat)}
						>
							{
								t(
									`faqs-page.sidebar.categories.${cat}` as TranslationKey
								) as string
							}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
