import React, { Dispatch, SetStateAction } from "react";
import { Search } from 'react-feather';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type P = {
    search: string
    setSearch: Dispatch<SetStateAction<string>>
};

export const SearchBar = ({ search, setSearch }: P) => {
    const { t } = useTranslation()

    return (
        <div className="relative">
            <Search className="absolute text-gray-200 inset-4" />
            <input
                type="search"
                value={search}
                className="p-4 pl-16 ring-2 ring-gray-100 rounded-md w-full"
                placeholder={t('faqs-page.search')}
                onChange={(e) => setSearch(e.target.value)}
            />
        </div>
    )
};
