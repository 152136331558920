import React from "react"
import { Container } from 'components/library';
import { graphql } from 'gatsby';
import { Wrapper } from "components/pages/faqs/faqs-wrapper";

const Faqs = () => {
  return (
    <Container className='py-12 sm:py-20'>
      <Wrapper />
    </Container>
  )
}

export default Faqs

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 