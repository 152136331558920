import React, { useMemo, useState } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { Faq } from 'types/faqs';
import { usePagination } from 'utils/general';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Pagination } from "components/common/pagination/pagination";
import { SearchBar } from "./faqs-search-bar";
import { List } from "./faqs-list";
import { Sidebar } from "./faqs-sidebar";

type Query = {
    allFaq: {
        nodes: Faq[]
    }
}

const pageSize = 10

export const Wrapper = () => {
    const { allFaq: { nodes: faqs } } = useStaticQuery<Query>(graphql`
        {
            allFaq {
              nodes {
                    id
                    question
                    answer
                    categories {
                        id
                        name
                    }
                }
            }
        }
    `)
    const [category, setCategory] = useState("")
    const [search, setSearch] = useState("")
    const { t } = useTranslation()

    const data = useMemo(() => {
        return faqs
            .filter(faq => {
                if (category === '') return true
                return faq.categories.some(cat => cat.name === category)
            })
            .filter(faq => {
                const question = faq.question.toLowerCase()
                const answer = faq.answer.toLowerCase()
                const faqCats = faq.categories.map(f => f.name.toLowerCase())
                const query = search.toLowerCase()

                return question.includes(query) || answer.includes(query) || faqCats.includes(query)
            })
    }, [search, category])

    const { currentPage, pageCount, pageData, setCurrentPage } = usePagination({ data, pageSize })

    const categories = useMemo(() => {
        return [...new Set(faqs.flatMap(faq => faq.categories.map(cat => cat.name)))].filter(cat => !cat.includes("Website"))
    }, [])

    return (
        <section className='flex sm:flex-row flex-col gap-20 sm:items-start'>
            <div className='grow'>
                <h1 className="text-4xl tracking-wider sm:text-5xl pb-6 sm:pb-12">
                    {t('faqs-page.heading')}
                </h1>

                <SearchBar search={search} setSearch={setSearch} />

                <List
                    data={pageData}
                    currentCategory={category}
                    setCategory={setCategory}
                />

                {data.length > 0 && (
                    <Pagination
                        onItemClick={(number) => setCurrentPage(number)}
                        currentPage={currentPage}
                        pageCount={pageCount}
                        onNext={() => setCurrentPage(currentPage + 1)}
                        onBack={() => setCurrentPage(currentPage - 1)}
                    />
                )}
            </div>

            <Sidebar categories={categories} setCategory={setCategory} currentCategory={category} />
        </section>
    )
};
