import React, { Dispatch, SetStateAction } from "react";
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import { Faq } from 'types/faqs';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TranslationKey } from 'types/translation';

type P = {
    data: Faq[]
    currentCategory: string
    setCategory: Dispatch<SetStateAction<string>>
};

export const List = ({ data, currentCategory, setCategory }: P) => {
    const { t } = useTranslation()

    return (
        <section className='py-10 flex flex-col gap-8'>
            {data.length < 1 && (
                <p>
                    <Trans
                        i18nKey="faqs-page.not-found-message"
                        components={{
                            anchor: <Link to="/support" />,
                            spantag: <span />
                        }}
                    />
                </p>
            )}

            {data.length > 0 && (
                <h3 className='text-xl'>{currentCategory === '' ? t('faqs-page.default-subheading') : t(`faqs-page.sidebar.categories.${currentCategory}` as TranslationKey) as string}</h3>
            )}

            {data.map(faq => (
                <div key={faq.id} className="flex flex-col gap-3">
                    <p className="text-theme-blue text-lg font-medium">{faq.question}</p>

                    <div className='flex flex-col gap-2'>
                        <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        <ul className="flex gap-2 flex-wrap">
                            {faq.categories.filter(cat=>!cat.name.includes("Website")).map(cat => {
                                return (
                                    <li
                                        key={cat.id}
                                        className="cursor-pointer text-xs text-theme-blue"
                                        onClick={() => setCategory(cat.name)}
                                    >
                                        {t(`faqs-page.sidebar.categories.${cat.name}` as TranslationKey) as string}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            ))}
        </section>
    )
};
